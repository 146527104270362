import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from "../components/assets/logo-pet.png";
import Spinner from '../components/Utilities/Spinner';
import '../css/Login.css';
import axios from 'axios';
import '../config';
import { Banner } from '../components/Utilities/Snackbar';
import { TextField } from '@mui/material';

const ChangePassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordTouched, setPasswordTouched] = useState(false);
  const [confirmPasswordTouched, setConfirmPasswordTouched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const navigate = useNavigate();
  const location = useLocation();

  const token = new URLSearchParams(location.search).get('forgotPasswordToken');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await axios.post(`${global.config.serverUrl}/changePassword/`, {
        forgotPasswordToken: token,
        newPassword: password,
      });

      setIsLoading(false);
      setSnackbarMessage('Password reset successful. You can now log in.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);

      setTimeout(() => {
        navigate('/');
      }, 3000);

    } catch (error) {
      setIsLoading(false);
      setSnackbarMessage('An error occurred while resetting your password.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const isPasswordValid = password.length >= 8;
  const doPasswordsMatch = password === confirmPassword;
  const isFormValid = isPasswordValid && doPasswordsMatch;

  return (
    <div className="login-container">
      <div className="login-form">
        <h1 className="login-title">Reset Password</h1>
        <img
          style={{ width: '320px', height: '420px', marginTop: '-130px', marginBottom: '-150px' }}
          src={logo}
          alt="Logo"
        />
        <form onSubmit={handleSubmit}>
          <div className="input-container">
            <TextField
              fullWidth
              type="password"
              label="New Password"
              variant="outlined"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setPasswordTouched(true);
              }}
              onBlur={() => setPasswordTouched(true)}
              error={passwordTouched && !isPasswordValid}
              helperText={passwordTouched && !isPasswordValid ? 'Password must be at least 8 characters long' : ''}
            />
          </div>
          <div className="input-container">
            <TextField
              fullWidth
              type="password"
              label="Confirm Password"
              variant="outlined"
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                setConfirmPasswordTouched(true);
              }}
              onBlur={() => setConfirmPasswordTouched(true)}
              error={confirmPasswordTouched && !doPasswordsMatch}
              helperText={confirmPasswordTouched && !doPasswordsMatch ? 'Passwords do not match' : ''}
            />
          </div>
          <button
            type="submit"
            disabled={!isFormValid}
            fullWidth
          >
            Reset Password
          </button>
        </form>
      </div>
      <Spinner open={isLoading} />
      <Banner
        open={snackbarOpen}
        handleClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </div>
  );
};

export default ChangePassword;
