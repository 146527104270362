import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import logo from "../components/assets/logo-pet.png";
import Spinner from '../components/Utilities/Spinner';
import { emailRegex } from '../components/Utilities/Utils';
import '../css/Login.css';
import axios from 'axios';
import '../config';
import { Banner } from '../components/Utilities/Snackbar';
import { TextField } from '@mui/material';
import { Link } from 'react-router-dom';

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [emailTouched, setEmailTouched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [isSuccess, setIsSuccess] = useState(false); // New state to track success

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await axios.post(`${global.config.serverUrl}/forgotPasswordRequest/`, {
        email,
      });

      setIsLoading(false);
      setIsSuccess(true); // Set success to true on API success
      setSnackbarMessage('Email sent successfully.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      setIsLoading(false);
      setSnackbarMessage('An error occurred while resetting your password.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const isEmailValid = emailRegex.test(email);
  const isFormValid = isEmailValid;

  return (
    <div className="login-container">
      <div className="login-form">
        <h1 className='login-title'>Reset Password</h1>
        <Link to="/" style={{ textDecoration: 'none' }}>
          <img
            style={{ width: '320px', height: '420px', marginTop: '-130px', marginBottom: '-150px' }}
            src={logo}
            alt='Logo'
          />
        </Link>

        {!isSuccess ? ( // Conditionally render form or success message
          <form onSubmit={handleSubmit}>
            <div className="input-container">
              <TextField
                fullWidth
                label="Email"
                variant="outlined"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setEmailTouched(true);
                }}
                onBlur={() => setEmailTouched(true)}
                error={emailTouched && !emailRegex.test(email)}
                helperText={emailTouched && !emailRegex.test(email) ? 'Invalid email address' : ''}
              />
            </div>
            <button type="submit" disabled={!isFormValid}>Send reset password link</button>
          </form>
        ) : (
          <p className="success-message">
            Follow the steps on your email to reset your password.
          </p>
        )}
      </div>
      <Spinner open={isLoading} />
      <Banner
        open={snackbarOpen}
        handleClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </div>
  );
};

export default ResetPassword;
